import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/layout'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/CloudControl'
	},

	{
		path: '*',
		redirect: '/404'
	},

	{
		path: '/404',
		component: () => import('@/views/unknown/unknown')
	},

	{ // 首页
		path: '/CloudControl',
		component: Layout,
		children: [
			{
				path: '',
				name: 'CloControl',
				component: () => import('@/views/CloudControl/CloControl')
			}
		]
	},

	{ // 规划明细
		path: '/plans',
		component: Layout,
		children: [
			{
				path: '',
				name: 'plans',
				component: () => import('@/views/plans/plans')
			}
		]
	},
	{ // 预约轮候
		path: '/appointment-waiting',
		component: Layout,
		children: [
			{
				path: '',
				name: 'plans',
				component: () => import('@/views/appointment-waiting/appointment-waiting')
			}
		]
	},
	{ // 登录页
		path: '/login',
		component: () => import('@/views/login/login.vue')
	},

	{
		path: '/map',
		component: () => import('@/views/map/map.vue')
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
