<template>
  <header>
    <div class="header-container">
      <div class="left-info">
        <div class="main-title">定边县智慧市场监管服务平台</div>
      </div>

      <div class="tab-list">
        <div :class="['tab-item', {'active': path === '/CloudControl'}]" @click="toRouterPath('/CloudControl',0)">合理化布局</div>
        <div :class="['tab-item', {'active': path === '/plans'}]" @click="toRouterPath('/plans',1)">规划明细</div>
        <div :class="['tab-item', {'active': path === '/appointment-waiting'}]" @click="toRouterPath('/appointment-waiting',1)">预约轮候</div>
      </div>

      <div class="right-info">
        <div class="current-date">{{ getDate() }}</div>
        <div class="current-week">{{ getWeek() }}</div>
        <div class="current-time">{{ time }}</div>
      </div>

      <div class="right-content">

        <div class="user-info">
          <div class="user-info-content">
            <img class="avatar" src="http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/user.png" alt="" />
            <div class="info">
              <span class="username">{{ user_name }}</span>
<!--              <span class="role">{{ admin_name }}</span>-->
            </div>
          </div>
        </div>

        <el-popconfirm
            confirm-button-text='确认'
            cancel-button-text='取消'
            confirm-button-type="text"
            @confirm="handleLoginOut"
            icon="el-icon-info"
            icon-color="#f90"
            title="是否要退出登录？">
          <img
              slot="reference"
              class="about-icon"
              src="http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/login-out.png"
              alt="" />
        </el-popconfirm>
      </div>

    </div>

    <div class="bg-block"></div>
  </header>
</template>

<script>
import {mapGetters} from "vuex";
import {Auth} from "@/utils/auth.js";

export default {
  name: "common-header",

  data() {
    return {
      Auth,
      time:'00:00:00',
      timeInt:null,
      is_checked: 0,
    }
  },

  computed: {
    ...mapGetters([
      "user_name",
      "token",
      'admin_name'
    ]),

    path() {
      return this.$route.path
    }
  },

  async mounted() {
    if (Auth.isLogin()) {
      // 获取普通用户信息
      await this.$store.dispatch('user/getUserInfo')
    }

    console.log(this.admin_name,65465)
    this.timeInt =  setInterval(()=>{
      this.getTime()
    },1000)
  },

  beforeDestroy(){
    clearInterval(this.timeInt)
  },

  methods: {

    toHomePage() {
      if (this.route === '/home') return
      this.$router.replace('/')
    },


    // 名字太长用省略号
    formatEllipsisText(text) {
      if (typeof text !== 'string' || text === '') {
        return ''
      }
      return text.substring(0, 5) + '...'
    },

    toRouterPath(path,index){
      if (this.path === path) return
      this.is_checked = index
      this.$router.push({path:path})
    },

    getDate(){
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // 注意月份从 0 开始，所以需要加 1
      const day = currentDate.getDate();

      return `${year}-${month}-${day}`
    },

    getWeek(){
      const currentDate = new Date();
      const weekDays = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      const currentWeekDay = weekDays[currentDate.getDay()];

      return currentWeekDay
    },

    getTime(){
      const currentDate = new Date();
      const hours = String(currentDate.getHours()).padStart(2, '0'); // 保证小时为两位数
      const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // 保证分钟为两位数
      const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // 保证秒数为两位数

      this.time = `${hours}:${minutes}:${seconds}`
    },


    // 退出登录
    async loginOut() {
      await this.$store.dispatch('user/logout')
    },


    // 退出登录
    async handleLoginOut() {
      const {level, message} = await this.$api.loginOut();
      if (level === 'success') {
        this.$notify.success(message);
        await this.$store.dispatch('logout')
      }
    },

  },
}
</script>

<style lang="scss" scoped>
header {
  position: relative;
  display: flex;
  align-items: center;
  min-width: $max-width;
  width: 100%;
  height: 100px;
  padding: 5px;

  .header-container {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    gap: 20px;
    width: 100%;
    height: 91px;
    padding: 12px 28px 0 46px;
    //background: url('@/assets/images/header_bg.png') no-repeat scroll 0 12px / 100% auto;
    background: url('@/assets/images/top_header2.gif') no-repeat scroll 0 12px / 100% auto;
    font-size: 14px;
    margin: 0 auto;

    .left-info {
      @include flex();
      //width: 500px;
      padding-top: 12px;
      .main-logo {
        width: 133px;
        height: 64px;
        background-image: url('@/assets/images/logo_bg.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .logo-image {
          @include image(30px, 30px);
          margin: 4px auto 0;
        }
      }
      .main-title {
        height: 44px;
        margin-left: 160px;
        margin-top: 10px;
        font-size: 26px;
        letter-spacing: 4px;
        line-height: 44px;
        white-space: nowrap;
        color: #8595a6;
        font-weight: 700;
        background: -webkit-linear-gradient(#edf6ff, #8595A6);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .tab-list {
      @include flex(normal, center);
      margin: 4px 0 0 0;

      .tab-item {
        width: 170px;
        height: 46px;
        background-color: rgba(255, 255, 255, .08);
        line-height:46px;
        text-align: center;
        color: #d8d8d8;
        font-size: 24px;
        border-radius: 30px;
        cursor: pointer;
        & + .tab-item {
          margin-left: 10px;
        }
        &.active {
          background-color: #1f3754;
          color: #32e3c8;
          //color: #FFFFFF;
          //border-color: #32e3c8;
        }
      }
    }

    .right-info {
      @include flex(normal, center, 40px);
      align-items: center;
      gap: 40px;
      padding-top: 15px;
      //background-color: #1F81FF;

      width: 480px;
      padding-bottom: 15px;
      color: #acc9e6;
      font-weight: 700;
      letter-spacing: 1px;
      .current-date {
        font-size: 24px;
      }
      .current-week {
        font-size: 22px;
      }
      .current-time {
        font-size: 38px;
      }
    }

    .right-content {
      position: relative;
      z-index: 2;
      display: flex;
      //width: 100px;
      justify-content: flex-start;
      align-items: center;
      //padding: 15px 0 0 30px;
      //background-color: #1F81FF;


      .about-icon {
        display: block;
        width: 48px;
        margin: 0 15px;
        cursor: pointer;
        transition: opacity .1s;

        &:hover {
          opacity: .70;
        }
      }

      .user-info {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &::after {
          content: "";
          position: absolute;
          top: 12%;
          left: 0;
          height: 80%;
          width: 1px;
          //border-left: 1px dashed #4C6D98;
        }

        .user-info-content {
          display: flex;
          align-items: center;
          min-width: 140px;
          padding: 6px 10px;
          background-color: #213c87;
          border-radius: 30px;

          .avatar {
            display: block;
            width: 30px;
            margin-right: 10px;
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .username {
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              white-space: nowrap;
              // text-transform: capitalize;
            }
            .role {
              color: #81A9F1;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }

  }

  .bg-block {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 90px;
    //background: url('@/assets/images/header_bg2.png') no-repeat scroll top right / cover;
    opacity: .2;
  }
}


</style>
