class Auth {

	static token_key = 'vue_admin_template_token'

	static request_key = "Authorization"

	static getToken() {
		return window.localStorage.getItem(this.token_key) || '';
	}

	static setToken(token) {
		window.localStorage.setItem(this.token_key, token)
	}

	static removeToken() {
		window.localStorage.setItem(this.token_key, '')
	}

	static isLogin() {
		return !!this.getToken()
	}

}

export {
	Auth
}
