import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {scrollBoard} from '@jiaminghi/data-view'
import ElementUI from 'element-ui'
import { VueJsonp } from 'vue-jsonp'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

// import '@/icons'
import '@/router/permission'
import * as currency from './utils/index'

import * as api from './api'

import 'normalize.css/normalize.css'
import '@/styles/index.scss'

import countTo from 'vue-count-to'
import { encrypt } from "./utils/encryp";
import "@/utils/gt4"

Vue.config.productionTip = false
Vue.component('elImageViewer', ElImageViewer)
Vue.use(ElementUI)
Vue.use(VueJsonp)
Vue.use(scrollBoard)
Vue.component('countTo', countTo);

Vue.prototype.$currency = currency;
Vue.prototype.$encrypt = encrypt;
Vue.prototype.$api = api;
window._AMapSecurityConfig = { securityJsCode: 'c3fcdc9b48f01845f26af6baa529fc08' }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
