import {Auth} from '@/utils/auth'
import router from '@/router'
import {logout, getUserInfo, getAdminUserInfo,} from '@/api'
import { Notification } from 'element-ui'

const getDefaultState = () => {
	return {
		token: Auth.getToken(),
		user_name: '',
		admin_name:'',
	}
}
const state = getDefaultState()

const mutations = {
	// 设置token
	SET_TOKEN: (state) => {
		state.token = Auth.getToken()
	},
	SET_NAME: (state,admin_name) => {
		state.admin_name = admin_name
	},
	SET_username: (state,user_name) => {
		state.user_name = user_name
	},
	// 保存用户信息
	setUserInfo: (state, payload) => {
		state.username = payload.username
		// state.admin_name = payload.admin_name
		state.login_name = payload.login_name
		state.org_area_id = payload.org_area_id
		state.unit_name = payload.unit_name
	},
}

const actions = {

	// 普通登录
	/*login({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			loginByPhone(params).then(async({ data }) => {
				if (data.level === 'success') {
					Auth.setToken(data.data)
					await this.dispatch('user/getUserInfo')
					Notification.success(data.message)
					resolve(data.data)
				} else {
					Notification.warning(data.message)
					reject(data.message)
					console.error(data.message)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},*/

	// 获取管理员信息
	getUserInfo() {
		return new Promise((resolve, reject) => {
			getAdminUserInfo().then(response => {
				const { data } = response.data
				if (!data) {
					return reject('Verification failed, please Login again.')
				}

				this.commit('user/SET_NAME', data.admin_name);
				this.commit('user/SET_username', data.username);
				// console.log('看看',fatherMenuPermission,childrenMenuPermission)
				resolve(data)
			}).catch(error => {
				reject(error)
			})
		})
	},




	// 退出登录
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			logout().then(() => {
				Auth.removeToken()
				this.commit('user/SET_TOKEN')
				this.commit('user/SET_USER_NAME', '')
				this.commit('user/SET_username', '')
				Notification.success('退出成功 !')
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
